declare global {
  interface Window {
    ReactNativeWebView?: any;
  }
}
function isInICTMobileApp() {
  const userAgent = window.navigator.userAgent || "";
  return !!window.ReactNativeWebView || /wv|WebView/i.test(userAgent);
}

export default isInICTMobileApp;
