import { SidebarLeftLayout } from "ict24h-themes-react";
import type { FC } from "react";

import { dockConfig } from "../home/dock-config";
import isInICTMobileApp from "../../utils/is-ict-mobile-app";

interface HRMLayoutProps {
  menu: React.ReactNode;
  routers: React.ReactNode;
}

const isInMobileApp = isInICTMobileApp();

const HRMLayoutCustom: FC<HRMLayoutProps> = ({ menu, routers }) => (
  <SidebarLeftLayout
    menu={menu}
    headerConfig={null}
    dock={isInMobileApp ? undefined : dockConfig}
    contentCss={isInMobileApp ? { padding: 0 } : undefined}
    leftSidebarCss={isInMobileApp ? { marginLeft: 0 } : undefined}
  >
    {routers}
  </SidebarLeftLayout>
);

export default HRMLayoutCustom;
