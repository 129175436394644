import PortalLayout from "./modules/portal/PortalLayout";
import { AppRouterItem } from "ict24h-themes-react/lib/esm/modules/routers/AppRouters";
import { LoadingLazyComponents, NotFoundPage } from "ict24h-themes-react";
import HomeDashboard from "./modules/home/HomeDashboard";
import SpFilesHome from "./modules/sp-files/SpFiles.home";
import QuanLyPheDuyetLayout from "./modules/quan-ly-phe-duyet/QuanLyPheDuyetLayout";
import { QUAN_LY_PHE_DUYET_PREFIX_URL } from "./modules/quan-ly-phe-duyet/constants/config.quan-ly-phe-duyet";
import ConfigWorkflowLayout from "./modules/config-workflows/ConfigWorkflowLayout";
import QuanLyDuAnDauTuLayout from "./modules/quan-ly-du-an-dau-tu/QuanLyDuAnDauTuLayout";
import ExtComponent from "./modules/external/index";

import LoginTeams from "./modules/Components/TeamsConsent/Login";
import ConsentPopup from "./modules/Components/TeamsConsent/ConsentPopup";
import ClosePopup from "./modules/Components/TeamsConsent/ClosePopup";

import EmbedIframe from "./components/EmbedIframe";
import { CONFIG_WORKFLOW_APP_PREFIX_URL } from "./modules/config-workflows/constants/config.home-app";
import {
  DMS_PREFIX_URL,
  HRM_PREFIX_URL,
  LMS_PREFIX_URL,
  QUAN_LY_DAO_TAO_PREFIX_URL,
  QUAN_LY_DIEU_DONG_LUAN_CHUYEN_NHAN_SU_PATH,
  QUAN_LY_KHO_PREFIX_URL,
  QUAN_LY_TAI_SAN_PREFIX_URL,
} from "@hoa00/ict24h-hrm-react-v2-1";

import HRMLayoutCustom from "./modules/hoa-build/hrm-layout-custom";

import HRMLayoutRouters from "@hoa00/ict24h-hrm-react-v2-1/lib/esm/modules/hrm-layout/HRMLayoutRouters";
import LeftMenuHrmLayout from "@hoa00/ict24h-hrm-react-v2-1/lib/esm/modules/hrm-layout/components/LeftMenu.HRMLayout";

import QuanLyDaoTaoLayoutRouters from "@hoa00/ict24h-hrm-react-v2-1/lib/esm/modules/quan-ly-dao-tao/QuanLyDaoTaoRouters";
import LeftMenuQuanLyDaoTaoLayout from "@hoa00/ict24h-hrm-react-v2-1/lib/esm/modules/quan-ly-dao-tao/components/LeftMenu.QuanLyDaoTao";

import DieuDongLuanChuyenNhanSuLayoutRouters from "@hoa00/ict24h-hrm-react-v2-1/lib/esm/modules/dieu-dong-luan-chuyen-nhan-su/DieuDongLuanChuyenNhanSuRouters";
import LeftMenuDieuDongLuanChuyenNhanSuLayout from "@hoa00/ict24h-hrm-react-v2-1/lib/esm/modules/dieu-dong-luan-chuyen-nhan-su/components/LeftMenu.DieuDongLuanChuyenNhanSu";

import QuanLyTaiSanLayoutRouters from "@hoa00/ict24h-hrm-react-v2-1/lib/esm/modules/quan-ly-tai-san/QuanLyTaiSanRouters";
import LeftMenuQuanLyTaiSanLayout from "@hoa00/ict24h-hrm-react-v2-1/lib/esm/modules/quan-ly-tai-san/components/LeftMenu.QuanLyTaiSan";

import QuanLyKhoLayoutRouters from "@hoa00/ict24h-hrm-react-v2-1/lib/esm/modules/quan-ly-kho/QuanLyKhoRouters";
import LeftMenuQuanLyKhoLayout from "@hoa00/ict24h-hrm-react-v2-1/lib/esm/modules/quan-ly-kho/components/LeftMenu.QuanLyKho";

import LMSLayoutRouters from "@hoa00/ict24h-hrm-react-v2-1/lib/esm/modules/lms/LmsRouters";
import LeftMenuLMSLayout from "@hoa00/ict24h-hrm-react-v2-1/lib/esm/modules/lms/components/LeftMenu.Lms";

import DMSLayoutRouters from "@hoa00/ict24h-hrm-react-v2-1/lib/esm/modules/tai-lieu/DMSRouters";
import LeftMenuDMSLayout from "@hoa00/ict24h-hrm-react-v2-1/lib/esm/modules/tai-lieu/components/LeftMenu.DMS";

const AppRouters: AppRouterItem[] = [
  {
    path: `/portal/*`,
    element: <PortalLayout />,
  },
  {
    path: `/pms/*`,
    element: <QuanLyDuAnDauTuLayout />,
  },
  {
    path: `/home`,
    element: <HomeDashboard />,
  },
  {
    path: `/sp-files/*`,
    element: <SpFilesHome />,
  },
  {
    path: `/ext/*`,
    element: <ExtComponent />,
  },
  {
    path: `/sp-files/*`,
    element: <SpFilesHome />,
  },
  {
    path: `/${QUAN_LY_PHE_DUYET_PREFIX_URL}/*`,
    element: <QuanLyPheDuyetLayout />,
  },

  // START: new HRM
  {
    path: `${HRM_PREFIX_URL}/*`,
    element: (
      <HRMLayoutCustom
        menu={<LeftMenuHrmLayout />}
        routers={<HRMLayoutRouters />}
      />
    ),
  },
  {
    path: `${QUAN_LY_DAO_TAO_PREFIX_URL}/*`,
    element: (
      <HRMLayoutCustom
        menu={<LeftMenuQuanLyDaoTaoLayout />}
        routers={<QuanLyDaoTaoLayoutRouters />}
      />
    ),
  },
  {
    path: `${QUAN_LY_DIEU_DONG_LUAN_CHUYEN_NHAN_SU_PATH}/*`,
    element: (
      <HRMLayoutCustom
        menu={<LeftMenuDieuDongLuanChuyenNhanSuLayout />}
        routers={<DieuDongLuanChuyenNhanSuLayoutRouters />}
      />
    ),
  },
  {
    path: `${QUAN_LY_TAI_SAN_PREFIX_URL}/*`,
    element: (
      <HRMLayoutCustom
        menu={<LeftMenuQuanLyTaiSanLayout />}
        routers={<QuanLyTaiSanLayoutRouters />}
      />
    ),
  },
  {
    path: `${QUAN_LY_KHO_PREFIX_URL}/*`,
    element: (
      <HRMLayoutCustom
        menu={<LeftMenuQuanLyKhoLayout />}
        routers={<QuanLyKhoLayoutRouters />}
      />
    ),
  },
  {
    path: `${LMS_PREFIX_URL}/*`,
    element: (
      <HRMLayoutCustom
        menu={<LeftMenuLMSLayout />}
        routers={<LMSLayoutRouters />}
      />
    ),
  },
  {
    path: `${DMS_PREFIX_URL}/*`,
    element: (
      <HRMLayoutCustom
        menu={<LeftMenuDMSLayout />}
        routers={<DMSLayoutRouters />}
      />
    ),
  },
  // END: new HRM

  // Start: HRM

  {
    path: `${CONFIG_WORKFLOW_APP_PREFIX_URL}/*`,
    element: <ConfigWorkflowLayout />,
    isNotNeedAuth: true,
  },
  {
    path: `/login-m365`,
    element: <LoginTeams />,
    isNotNeedAuth: true,
  },
  {
    path: `/login-m365-auth-start`,
    element: <ConsentPopup />,
    isNotNeedAuth: true,
  },
  {
    path: `/login-m365-auth-end`,
    element: <ClosePopup />,
    isNotNeedAuth: true,
  },
  {
    path: `/embed-iframe/workflow`,
    element: (
      <EmbedIframe src="https://i24h.sharepoint.com/sites/dev2/customer-view/phuc-thinh/apps/approval/default.aspx#/approval" />
    ),
  },
  // End: HRM
  {
    path: "*",
    element: <LoadingLazyComponents children={<NotFoundPage />} />,
  },
];

export default AppRouters;
